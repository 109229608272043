<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity } from '~/apiClient';

// PROPS
const { asset } = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const activeIndex = ref<number>(0);
const lightboxOpen = ref<boolean>(false);
const thumbs = computed<string[]>(() => asset.images.base.slice(0, 4));
</script>

<template>
    <div class="grid gap-6 md:grid-cols-[1fr_120px] md:gap-8">
        <NuxtImg
            class="col-start-1 row-start-1 aspect-[4/3] h-full w-full rounded-xl border object-cover"
            :src="asset.images.base[activeIndex]"
            :width="800"
            densities="x1"
            loading="lazy"
            :draggable="false"
        />
        <AdpLightboxButton class="col-start-1 row-start-1 m-3 self-start justify-self-end" @click="lightboxOpen = true" />

        <div class="grid grid-cols-3 gap-3 md:grid-cols-1">
            <NuxtImg
                v-for="(image, index) in thumbs"
                :key="image"
                class="m-auto aspect-[4/3] cursor-pointer rounded-xl border-2 object-cover"
                :class="{
                    'border-fl-dark-blue': index === activeIndex,
                    'col-start-3 row-start-1 md:col-start-1 md:row-start-3': index === 2,
                    'col-start-1 row-start-4 hidden md:block': index === 3,
                }"
                :src="image"
                :width="240"
                densities="x1"
                loading="lazy"
                :draggable="false"
                @click="activeIndex = index"
            />

            <UButton
                class="col-start-3 row-start-1 m-auto w-full md:col-start-1 md:row-start-4"
                :ui="{
                    base: 'flex-col justify-evenly aspect-[4/3]',
                    rounded: 'rounded-xl',
                    container: 'opacity-50',
                    truncate: 'text-center break-normal line-clamp-2',
                    color: { black: { solid: 'bg-gray-900/50 hover:bg-gray-800/50' } },
                }"
                color="black"
                :label="tt('adp.imageGallery', { totalImages: asset.images.base.length })"
                icon="i-heroicons-squares-2x2"
                truncate
                @click="lightboxOpen = true"
            />
        </div>

        <LazyAdpLightboxImage v-model="lightboxOpen" :items="asset.images.base" :initial-slide="activeIndex" />
    </div>
</template>
