<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionAssetBusEntity, type AuctionItemEntity } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { locale } = useI18n();

// PROPS
const { article } = defineProps({
    article: { type: Object as PropType<AuctionItemEntity | AuctionAssetBusEntity>, required: true },
});

// DATA
const featuredAsset = computed<AuctionAssetBusEntity>(() => (isAuctionEntity(article) ? article.mainAsset : article));
const auction = computed<AuctionItemEntity | undefined>(() => (isAuctionEntity(article) ? article : undefined));
const isBundleMono = computed<boolean>(() => isAuctionBundleMono(auction.value));
const hasDetails = computed<boolean>(() =>
    isBundleMono.value
        ? auction.value!.assets.some(_a => _a.damageNotes[locale.value] || _a.images.damage.length)
        : !!featuredAsset.value.damageNotes[locale.value] || !!featuredAsset.value.images.damage.length
);
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(auction.value));
</script>

<template>
    <section v-if="hasDetails" class="flex flex-col gap-6">
        <h2 class="text-lg font-semibold md:text-3xl">{{ tt('adp.detailsTitle', isBundleMono ? 2 : 1) }}</h2>

        <template v-if="isBundleMono">
            <template v-for="(asset, index) in sortedAssets" :key="asset.id">
                <BaseAccordion
                    v-if="!!asset.damageNotes[locale] || !!asset.images.damage.length"
                    :ui="{ default: { class: 'font-normal text-base' } }"
                    :items="[{ label: tt('asset.vehicleIndex', { index: index + 1 }), defaultOpen: index === 0 }]"
                >
                    <template #item>
                        <AdpDetailsItem :asset="asset" :index="index + 1" />
                    </template>
                </BaseAccordion>
            </template>
        </template>

        <AdpDetailsItem v-else :asset="featuredAsset" />
    </section>
</template>
