<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity } from '~/apiClient';

// COMPOSABLES
const { tt } = useTypedI18n();
const { isTabletOrDesktop } = useResponsive();
const { swiperElement, activeIndex } = useSwiperNavigation();

// PROPS
const { asset } = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// DATA
const imageLightboxOpen = ref<boolean>(false);
const videoLightboxOpen = ref<boolean>(false);
const imageLightboxSlide = ref<number>(0);
const videos = computed<string[]>(() => {
    const { engine, gearbox, other } = asset.videos;

    return [...engine, ...gearbox, ...other];
});

// HANDLERS
const openLightbox: (index: number) => void = index => {
    imageLightboxSlide.value = index;
    imageLightboxOpen.value = true;
};

const onLightboxIndexChange = (index: number): void => {
    swiperElement.value?.swiper.slideTo(index);
};
</script>

<template>
    <div class="flex flex-col gap-12">
        <div class="flex flex-col gap-8">
            <ClientOnly>
                <swiper-container
                    ref="swiperElement"
                    :lazy-preload-prev-next="1"
                    class="aspect-[4/3] max-w-full overflow-hidden rounded-xl"
                    :space-between="20"
                    thumbs-swiper=".adp-gallery-thumbs"
                    rewind="true"
                    virtual="true"
                >
                    <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute-->
                    <div slot="container-start">
                        <AdpLightboxButton class="absolute right-4 top-4 z-10 md:right-5 md:top-5" @click="openLightbox(activeIndex)" />
                    </div>
                    <swiper-slide v-for="image in asset.images.base" :key="image" lazy="true">
                        <NuxtImg
                            class="aspect-[4/3] h-full min-h-20 w-full cursor-grab rounded-xl border object-cover active:cursor-grabbing lg:min-h-24 xl:min-h-44"
                            :src="image"
                            :width="800"
                            densities="x1"
                            loading="lazy"
                            :draggable="false"
                        />
                    </swiper-slide>
                </swiper-container>
                <template #fallback>
                    <div class="flex aspect-[4/3] max-w-full overflow-hidden rounded-xl">
                        <NuxtImg
                            v-for="image in asset.images.base"
                            :key="image"
                            class="aspect-[4/3] h-full min-h-20 w-full rounded-xl border object-cover lg:min-h-24 xl:min-h-44"
                            :src="image"
                            :width="800"
                            densities="x1"
                            loading="lazy"
                            :draggable="false"
                        />
                    </div>
                </template>
            </ClientOnly>

            <AdpSwiperNavigation class="rounded-xl" :swiper-element="swiperElement" :active-index="activeIndex" rewind>
                <ClientOnly>
                    <swiper-container
                        class="adp-gallery-thumbs w-full shrink-0 rounded-xl px-2 md:px-4"
                        :space-between="20"
                        :slides-per-view="Math.min(3, asset.images.base.length)"
                        :lazy-preload-prev-next="isTabletOrDesktop ? 5 : 3"
                        :breakpoints="{
                            640: {
                                slidesPerView: Math.min(4, asset.images.base.length),
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: Math.min(5, asset.images.base.length),
                                spaceBetween: 28,
                            },
                            1024: {
                                slidesPerView: Math.min(5, asset.images.base.length),
                                spaceBetween: 32,
                            },
                        }"
                        rewind="true"
                        virtual="true"
                        watch-slides-progress="true"
                    >
                        <swiper-slide v-for="image in asset.images.base" :key="image" class="aspect-[4/3]" lazy="true">
                            <NuxtImg
                                class="aspect-[4/3] cursor-pointer rounded-xl border-2 object-cover"
                                :src="image"
                                :width="240"
                                densities="x1"
                                loading="lazy"
                                :draggable="false"
                            />
                        </swiper-slide>
                    </swiper-container>
                    <template #fallback>
                        <div class="grid grid-cols-3 gap-5 px-2 sm:hidden md:px-4">
                            <NuxtImg
                                v-for="(image, index) in asset.images.base.slice(0, 3)"
                                :key="image"
                                class="aspect-[4/3] rounded-xl border-2 object-cover"
                                :class="{ 'border-fl-dark-blue': index === 0 }"
                                :src="image"
                                :width="240"
                                densities="x1"
                                :draggable="false"
                            />
                        </div>
                        <div class="hidden grid-cols-4 gap-5 px-2 sm:grid md:hidden md:px-4">
                            <NuxtImg
                                v-for="(image, index) in asset.images.base.slice(0, 4)"
                                :key="image"
                                class="aspect-[4/3] rounded-xl border-2 object-cover"
                                :class="{ 'border-fl-dark-blue': index === 0 }"
                                :src="image"
                                :width="240"
                                densities="x1"
                                :draggable="false"
                            />
                        </div>
                        <div class="hidden grid-cols-5 gap-7 px-2 md:grid md:px-4 lg:gap-8">
                            <NuxtImg
                                v-for="(image, index) in asset.images.base.slice(0, 5)"
                                :key="image"
                                class="aspect-[4/3] rounded-xl border-2 object-cover"
                                :class="{ 'border-fl-dark-blue': index === 0 }"
                                :src="image"
                                :width="240"
                                densities="x1"
                                :draggable="false"
                            />
                        </div>
                    </template>
                </ClientOnly>
            </AdpSwiperNavigation>
        </div>

        <div class="flex flex-col justify-center gap-3 md:flex-row">
            <UButton
                class="md:justify-unset w-full justify-center md:w-auto"
                size="xl"
                color="white"
                :label="tt('adp.imageGallery', { totalImages: asset.images.base.length })"
                icon="i-heroicons-squares-2x2-solid"
                leading
                @click="openLightbox(0)"
            />
            <UButton
                v-if="videos.length"
                class="md:justify-unset w-full justify-center md:w-auto"
                size="xl"
                color="white"
                :label="tt('adp.videoGallery', { totalVideos: videos.length })"
                icon="i-heroicons-play-circle-20-solid"
                leading
                @click="videoLightboxOpen = true"
            />
        </div>

        <LazyAdpLightboxImage
            v-model="imageLightboxOpen"
            :items="asset.images.base"
            :initial-slide="imageLightboxSlide"
            @active-index-change="onLightboxIndexChange"
        />
    </div>
    <LazyAdpLightboxVideo v-model="videoLightboxOpen" :items="asset.videos" />
</template>

<style scoped>
.adp-gallery-thumbs :deep(.swiper-slide-thumb-active) {
    img {
        @apply border-fl-dark-blue;
    }
}
</style>
