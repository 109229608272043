<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionAssetBusListingItem, BusClass } from '~/apiClient';

// COMPOSABLE
const { tt, td, formatUnits } = useTypedI18n();

// PROPS
const { asset } = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusListingItem>, required: true },
});

// DATA
const classLabel = computed<string>(() => asset.class.map((_cl: BusClass) => tt(`asset.class.${_cl}`)).join(', '));
const firstRegistration = computed(() => Date.parse(asset.firstRegistration));
</script>

<template>
    <BaseArticleCardWrapper>
        <template #header>
            <AuctionCardImages :images="asset.images" />
        </template>

        <template #class> {{ classLabel }} </template>

        <template #model>{{ asset.brand.brandValue }} {{ asset.model.modelValue }}</template>

        <template #registration>{{ td(firstRegistration, 'mmyyyy') }}</template>

        <template #kilometer>{{ formatUnits.km(asset.km) }}</template>

        <template #euro-norm>{{ tt(`asset.euroNorm.${asset.euroNorm}`) }}</template>
    </BaseArticleCardWrapper>
</template>
