<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { invoke } from '@vueuse/core';

import { type AuctionItemEntity, type UserMeta } from '~/apiClient';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';
import { isAuctionItemEntityWithCurrentPrince } from '~/types/auction.type';
import { NotificationTimeout } from '~/types/notifications.type';

// PROPS
const props = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt, tn } = useTypedI18n();
const { isLoggedIn } = useAuthUtils();
const { openLoginModal } = useModals();
const { notifyError } = useNotification();
const queryClient = useQueryClient();
const { isBailNeeded, activeAutoBidAmount } = useAuctionStatusAdapter(toRef(() => props.auction));

// DATA
const userMeta = computed<UserMeta | undefined>(() => props.auction.userMeta);
const isInspectionExpiredOpen = ref<boolean>(false);
const isBailModalOpen = ref<boolean>(false);
const bailSuccess = ref<boolean>(false);
const isBidModalOpen = ref<boolean>(false);
const refetchAuctionDetailInProgress = ref<boolean>(false);
const shouldShowInspectionExpiredModal = computed(() => !!userMeta.value?.inspectionExpiredExportRestrictions && isBailNeeded.value);

// HANDLERS
const refetchAuctionDetail = async (): Promise<void> => {
    refetchAuctionDetailInProgress.value = true;
    await queryClient.refetchQueries({ queryKey: [AUCTION_DETAIL_QUERY_KEY_PREFIX], type: 'active' });
    refetchAuctionDetailInProgress.value = false;
};

const login = (): void =>
    openLoginModal({
        onSuccess: () => {
            invoke(async () => {
                // Auction detail is already refetch by the isLoggedIn watcher in page component, just wait for userMeta sto be et
                await until(userMeta).toBeTruthy();
                if (isBailNeeded.value) isBailModalOpen.value = true;
            });
        },
    });

const onBailSuccess = (): void => {
    isBailModalOpen.value = false;
    bailSuccess.value = true;
};

const onBailFail = (): void => {
    isBailModalOpen.value = false;
    notifyError({ title: tt('notifications.genericError'), description: tt('auction.bail.error'), timeout: NotificationTimeout.Long });
};

const onBidButtonClick = (): void => {
    if (!isLoggedIn.value) {
        login();
    } else if (shouldShowInspectionExpiredModal.value) {
        isInspectionExpiredOpen.value = true;
    } else if (isBailNeeded.value) {
        isBailModalOpen.value = true;
    } else if (userMeta.value?.canBid) {
        isBidModalOpen.value = true;
    }
};

// WATCHERS
watch(isLoggedIn, () => {
    // Reset internal state on login change
    isBailModalOpen.value = false;
    bailSuccess.value = false;
    isBidModalOpen.value = false;
});
</script>

<template>
    <span v-if="activeAutoBidAmount" class="text-center lg:text-left">
        {{ tt('auction.bid.activeAutoBid') }}:&nbsp;<span class="text-primary font-medium">{{ tn(activeAutoBidAmount, 'currency') }}</span>
    </span>

    <AdpAuctionPanelBidButton
        class="mx-auto max-w-60 lg:max-w-none"
        :loading="refetchAuctionDetailInProgress"
        :active-auto-bid="!!userMeta?.autoBid?.active"
        @click="onBidButtonClick"
    />

    <LazyAdpInspectionExpiredModal v-if="shouldShowInspectionExpiredModal" v-model="isInspectionExpiredOpen" @confirm="isBailModalOpen = true" />

    <LazyAdpBailModal v-if="isBailNeeded" v-model="isBailModalOpen" :auction="auction" @success="onBailSuccess" @fail="onBailFail" />

    <LazyAdpBidModal
        v-if="userMeta?.canBid && isAuctionItemEntityWithCurrentPrince(auction)"
        v-model="isBidModalOpen"
        :auction="auction"
        @success="refetchAuctionDetail"
    />
</template>
