<script setup lang="ts">
import { countries } from '@lib/shared';
import lodashSortBy from 'lodash-es/sortBy';
import lodashToNumber from 'lodash-es/toNumber';
import lodashToString from 'lodash-es/toString';

import { AssetStateOfUse, type AuctionAssetBusEntity, type AuctionItemEntity } from '~/apiClient';
import type { AssetSpecItem, AssetSpecsGroup, AssetSpecsSection } from '~/types/assetSpecs.type';

// PROPS
const { article } = defineProps({
    article: { type: Object as PropType<AuctionItemEntity | AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt, formatUnits, td } = useTypedI18n();
const { locale } = useTypedI18n();

// COMPUTED
const featuredAsset = computed<AuctionAssetBusEntity>(() => (isAuctionEntity(article) ? article.mainAsset : article));
const auction = computed<AuctionItemEntity | undefined>(() => (isAuctionEntity(article) ? article : undefined));
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(auction.value));
const isBundleMono = computed<boolean>(() => isAuctionBundleMono(auction.value));

const bundleSpecs = computed<AssetSpecsSection>(() =>
    sortedAssets.value.map(_a => [
        ['asset.firstRegistration', td(_a.firstRegistration, 'mmyyyy')],
        ['asset.kilometers', formatUnits.km(_a.km)],
        ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${_a.vehicleCondition}`)],
        ...insertIf<AssetSpecItem>(!!_a.useType, () => ['asset.useType.base', tt(`asset.useType.${_a.useType}`)]),
        ['asset.vin', _a.vin],
        ['asset.inspectionExpiryDate', td(_a.inspectionExpiryDate, 'mmyyyy')],
        ['asset.registrationDocumentCountry', getCountryTranslation(_a)],
    ])
);
const descriptionSpecs = computed<AssetSpecsSection>(() => [
    ...insertIf<AssetSpecsGroup>(
        isBundleMono.value,
        [
            ['asset.antiPollution', tt(`asset.euroNorm.${featuredAsset.value.euroNorm}`)],
            ['asset.class.base', featuredAsset.value.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
            ['asset.version', featuredAsset.value.version.versionValue],
            ['asset.geographicalLocation', featuredAsset.value.geographicalLocation],
            [
                'asset.visitDate.base',
                featuredAsset.value.visitDateFrom && featuredAsset.value.visitDateTo
                    ? tt('asset.visitDate.fromTo', {
                          date: td(featuredAsset.value.visitDateFrom, 'ddmmyyyy'),
                          fromTime: td(removeTimezone(featuredAsset.value.visitDateFrom), 'hh'),
                          toTime: td(removeTimezone(featuredAsset.value.visitDateTo), 'hh'),
                      })
                    : null,
            ],
        ],
        [
            ['asset.converter', featuredAsset.value.bodyBuilder],
            ['asset.deckType.base', tt(`asset.deckType.${featuredAsset.value.deckType}`)],
            ['asset.length', formatUnits.meters(featuredAsset.value.length)],
            ['asset.width', formatUnits.meters(featuredAsset.value.width)],
            ['asset.height', formatUnits.meters(featuredAsset.value.height)],
        ]
    ),
    ...insertIf<AssetSpecsGroup>(
        !isBundleMono.value,
        [
            ['asset.firstRegistration', td(featuredAsset.value.firstRegistration, 'mmyyyy')],
            ['asset.kilometers', formatUnits.km(featuredAsset.value.km)],
            ['asset.antiPollution', tt(`asset.euroNorm.${featuredAsset.value.euroNorm}`)],
            ['asset.class.base', featuredAsset.value.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
            ['asset.version', featuredAsset.value.version.versionValue],
            ['asset.geographicalLocation', featuredAsset.value.geographicalLocation],
            [
                'asset.visitDate.base',
                featuredAsset.value.visitDateFrom && featuredAsset.value.visitDateTo
                    ? tt('asset.visitDate.fromTo', {
                          date: td(featuredAsset.value.visitDateFrom, 'ddmmyyyy'),
                          fromTime: td(removeTimezone(featuredAsset.value.visitDateFrom), 'hh'),
                          toTime: td(removeTimezone(featuredAsset.value.visitDateTo), 'hh'),
                      })
                    : null,
            ],
        ],
        [
            ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${featuredAsset.value.vehicleCondition}`)],
            ...insertIf<AssetSpecItem>(!!featuredAsset.value.useType, () => [
                'asset.useType.base',
                tt(`asset.useType.${featuredAsset.value.useType}`),
            ]),
            ['asset.vin', featuredAsset.value.vin],
            ['asset.converter', featuredAsset.value.bodyBuilder],
            ['asset.deckType.base', tt(`asset.deckType.${featuredAsset.value.deckType}`)],
            ['asset.length', formatUnits.meters(featuredAsset.value.length)],
            ['asset.width', formatUnits.meters(featuredAsset.value.width)],
            ['asset.height', formatUnits.meters(featuredAsset.value.height)],
            ['asset.inspectionExpiryDate', td(featuredAsset.value.inspectionExpiryDate, 'mmyyyy')],
            ['asset.registrationDocumentCountry', getCountryTranslation(featuredAsset.value)],
        ]
    ),
    [
        ['asset.doors.base', tt(`asset.doors.${featuredAsset.value.doors}`)],
        ['asset.totalCapacity', featuredAsset.value.totalCapacity],
        ['asset.seats', featuredAsset.value.seats],
        ['asset.seatsFromS1', featuredAsset.value.seatsFromS1],
        ['asset.standingPlaces', featuredAsset.value.standingPlaces],
        ['asset.standingPlacesFromS2', featuredAsset.value.standingPlacesFromS2],
        ['asset.companionSeats', featuredAsset.value.companionSeats],
        ['asset.foldingSeats', featuredAsset.value.foldingSeats],
        ['asset.wheelchairPlaces', featuredAsset.value.wheelchairPlaces],
        ['asset.wheelchairRamp.base', tt(`asset.wheelchairRamp.${featuredAsset.value.wheelchairRamp}`)],
    ],
    sortFalseLast([
        ['asset.luggageCapacity', formatUnits.cubicMeters(lodashToNumber(featuredAsset.value.luggageCapacity))],
        ['asset.passThroughLuggageCompartment', featuredAsset.value.passThroughLuggageCompartment],
    ]),
]);

const conditionsSpecs = computed<AssetSpecsSection>(() =>
    isBundleMono.value ? sortedAssets.value.map(_a => getConditionSpecs(_a)) : [getConditionSpecs(featuredAsset.value)]
);

const transmissionSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.gearboxType.base', tt(`asset.gearboxType.${featuredAsset.value.gearboxType}`)],
        ['asset.gearboxBrand', featuredAsset.value.gearboxBrand],
        ['asset.gearboxModel', featuredAsset.value.gearboxModel],
    ],
]);

const engineSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.fuel.base', tt(`asset.fuel.${featuredAsset.value.fuel}`)],
        ['asset.engineBrand', featuredAsset.value.engineBrand],
        ['asset.engineModel', featuredAsset.value.engineModel],
        ['asset.enginePosition', tt(`asset.position.${featuredAsset.value.enginePosition}`)],
    ],
    [
        ['asset.displacement', formatUnits.cc(featuredAsset.value.displacement)],
        ['asset.hp', featuredAsset.value.hp],
        ['asset.kw', formatUnits.kw(featuredAsset.value.kw)],
        ['asset.cylinders', featuredAsset.value.cylinders],
    ],
    [
        ['asset.fuelTankCapacity', formatUnits.liters(featuredAsset.value.fuelTankCapacity)],
        ['asset.gasTanks', featuredAsset.value.gasTanks],
        ...insertIf<AssetSpecItem>(!!featuredAsset.value.gasTanksMaterial, () => [
            'asset.gasTanksMaterial.base',
            tt(`asset.gasTanksMaterial.${featuredAsset.value.gasTanksMaterial!}`),
        ]),
        ['asset.batteryPower', formatUnits.kwh(featuredAsset.value.batteryPower)],
        ['asset.chargingType', featuredAsset.value.chargingType],
    ],
]);

const wheelsSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.axles', featuredAsset.value.axles],
        ['asset.wheels', featuredAsset.value.wheels],
        ['asset.wheelsSizeAxle1', featuredAsset.value.wheelsSizeAxle1],
        ['asset.wheelsSizeAxle2', featuredAsset.value.wheelsSizeAxle2],
        ['asset.alloyWheels', featuredAsset.value.alloyWheels],
    ]),
]);

const accessoriesSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.acDriver', featuredAsset.value.acDriver],
        ['asset.acPassengers', featuredAsset.value.acPassengers],
        ['asset.heater', featuredAsset.value.heater],
        ['asset.preHeater', featuredAsset.value.preHeater],
    ]),
    sortFalseLast([
        ['asset.cruiseControl.base', tt(`asset.cruiseControl.${featuredAsset.value.cruiseControl}`)],
        ['asset.tachograph.base', tt(`asset.tachograph.${featuredAsset.value.tachograph}`)],
        ['asset.abs', featuredAsset.value.abs],
        ['asset.asr', featuredAsset.value.asr],
        ['asset.retarder', featuredAsset.value.retarder],
        ['asset.breathalyzer', featuredAsset.value.breathalyzer],
    ]),
    sortFalseLast([
        ['asset.cctv', featuredAsset.value.cctv],
        ['asset.internalCameras', featuredAsset.value.internalCameras],
        ['asset.externalCameras', featuredAsset.value.externalCameras],
        ['asset.retrocamera', featuredAsset.value.retrocamera],
    ]),
    [
        ['asset.firePreventionSystem', featuredAsset.value.firePreventionSystem],
        ['asset.firePreventionSystemBrand', featuredAsset.value.firePreventionSystemBrand],
    ],
    [
        ['asset.indicatorSigns', featuredAsset.value.indicatorSigns],
        ['asset.indicatorSignsBrand', featuredAsset.value.indicatorSignsBrand],
    ],
    sortFalseLast([
        ['asset.driverBerth', featuredAsset.value.driverBerth],
        ['asset.socket220', featuredAsset.value.socket220],
    ]),
    sortFalseLast([
        ['asset.wifi', featuredAsset.value.wifi],
        ['asset.microphone', featuredAsset.value.microphone],
        ['asset.cdPlayer', featuredAsset.value.cdPlayer],
        ['asset.dvdPlayer', featuredAsset.value.dvdPlayer],
        ['asset.usbPlayer', featuredAsset.value.usbPlayer],
        ['asset.monitors', featuredAsset.value.monitors],
        ['asset.mp3', featuredAsset.value.mp3],
    ]),
    sortFalseLast([
        ['asset.kitchen', featuredAsset.value.kitchen],
        ['asset.coffeeMachine', featuredAsset.value.coffeeMachine],
        ['asset.refrigerator', featuredAsset.value.refrigerator],
        ['asset.toilet', featuredAsset.value.toilet],
    ]),
    sortFalseLast([
        ...insertIf<AssetSpecItem>(!!featuredAsset.value.glasses, () => ['asset.glasses.base', tt(`asset.glasses.${featuredAsset.value.glasses!}`)]),
        ['asset.openableWindows', featuredAsset.value.openableWindows],
        ['asset.curtains', featuredAsset.value.curtains],
    ]),
    sortFalseLast([
        ['asset.seatsMonitor', featuredAsset.value.seatsMonitor],
        ['asset.seatsRadio', featuredAsset.value.seatsRadio],
        ['asset.seatsUsbPorts', featuredAsset.value.seatsUsbPorts],
        ['asset.seatsSocket220', featuredAsset.value.seatsSocket220],
    ]),
    sortFalseLast([
        ['asset.seatUpholstery.base', tt(`asset.seatUpholstery.${featuredAsset.value.seatUpholstery}`)],
        ['asset.recliningSeats', featuredAsset.value.recliningSeats],
        ['asset.extendableSeats', featuredAsset.value.extendableSeats],
    ]),
    sortFalseLast([
        ['asset.safetyBelts', featuredAsset.value.safetyBelts],
        ['asset.coffeeTables', featuredAsset.value.coffeeTables],
        ['asset.seatPocket', featuredAsset.value.seatPocket],
        ['asset.handRest', featuredAsset.value.handRest],
        ['asset.footRest', featuredAsset.value.footRest],
        ['asset.hatBox', featuredAsset.value.hatBox],
    ]),
]);

// METHODS
const getCountryTranslation = (_a: AuctionAssetBusEntity): string | null =>
    countries.find(_c => _c.countryCode === _a.registrationDocumentCountry)?.i18nNames?.[locale.value] ?? null;

const getConditionSpecs = (_a?: AuctionAssetBusEntity): AssetSpecsGroup => {
    if (!_a) return [];

    return [
        ...insertAssetPart(_a.enginePresent, _a.stateOfUseEngine, 'asset.engine'),
        ...insertAssetPart(_a.gearboxPresent, _a.stateOfUseGearbox, 'asset.transmission'),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUseAirConditioner, () => [
            'asset.stateOfUseAirConditioner',
            tt(`asset.stateOfUse.${_a.stateOfUseAirConditioner}`),
        ]),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUseBreakingSystem, () => [
            'asset.stateOfUseBreakingSystem',
            tt(`asset.stateOfUse.${_a.stateOfUseBreakingSystem}`),
        ]),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUseDrivingOrgans, () => [
            'asset.stateOfUseDrivingOrgans',
            tt(`asset.stateOfUse.${_a.stateOfUseDrivingOrgans}`),
        ]),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUsePowerSystem, () => [
            'asset.stateOfUsePowerSystem',
            tt(`asset.stateOfUse.${_a.stateOfUsePowerSystem}`),
        ]),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUseTimingBelt, () => [
            'asset.stateOfUseTimingBelt',
            tt(`asset.stateOfUse.${_a.stateOfUseTimingBelt}`),
        ]),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUseTransmissionDevices, () => [
            'asset.stateOfUseTransmissionDevices',
            tt(`asset.stateOfUse.${_a.stateOfUseTransmissionDevices}`),
        ]),
        ...insertIf<AssetSpecItem>(!!_a.stateOfUseTurbine, () => ['asset.stateOfUseTurbine', tt(`asset.stateOfUse.${_a.stateOfUseTurbine}`)]),
        ['asset.missingParts', lodashToString(_a.missingParts[locale.value]) || null],
    ];
};

const insertAssetPart = (present: boolean | null, stateOfUse: AssetStateOfUse | null, label: TranslationKey): AssetSpecItem[] => {
    if (present === false) return [[label, tt('asset.stateOfUse.missing')]];

    if (stateOfUse) return [[label, tt(`asset.stateOfUse.${stateOfUse}`)]];

    return [];
};

const removeTimezone = (date: string): string => date.replace(/Z$/, '');

const sortFalseLast = (items: AssetSpecItem[]): AssetSpecItem[] =>
    lodashSortBy(items, [(a): boolean | number => (typeof a[1] === 'boolean' ? !a[1] : 0)]);
</script>

<template>
    <section v-if="featuredAsset" class="flex flex-col gap-6">
        <div class="flex flex-col gap-6">
            <h3 class="text-2xl font-semibold">
                <template v-if="isBundleMono">{{ tt('asset.bundleSpecs') }}</template>
                <template v-else>{{ tt('asset.vehicleSpecs') }}</template>
            </h3>
            <p v-if="!!featuredAsset.additionalNotes[locale]">{{ featuredAsset.additionalNotes[locale] }}</p>
        </div>

        <AdpSpecsSection v-if="isBundleMono" :title="tt('asset.vehiclesInBundle')" :specs="bundleSpecs" default-open bundle-mono grid />

        <AdpSpecsSection :title="tt('asset.description')" :specs="descriptionSpecs" :default-open="!isBundleMono" grid />

        <AdpSpecsSection :title="tt('asset.conditionsAndMaintenance')" :specs="conditionsSpecs" :bundle-mono="isBundleMono" />

        <AdpSpecsSection :title="tt('asset.engine')" :specs="engineSpecs" grid />

        <AdpSpecsSection :title="tt('asset.transmission')" :specs="transmissionSpecs" grid />

        <AdpSpecsSection :title="tt('asset.axlesTires')" :specs="wheelsSpecs" grid />

        <AdpSpecsSection :title="tt('asset.accessories')" :specs="accessoriesSpecs" grid />

        <AdpDocuments :asset="featuredAsset" />
    </section>
</template>
