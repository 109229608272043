<script setup lang="ts">
import { AssetType, type AuctionAssetBusEntity, AuctionState, BackendErrorCode, getApiAuctionDetailQueryKey, useApiAuctionDetail } from '~/apiClient';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';

definePageMeta({
    key: 'auction-detail-static', // Prevent full page re-rendering on locale change
});

// COMPOSABLES
const { id, slug } = useAuctionDetailParams();
const { isLoggedIn, data: session } = useAuthUtils();
const localePath = useLocalePath();
const { tt, td, formatUnits } = useTypedI18n();
const image = useImage();

// DATA
const {
    data: auction,
    error,
    isError,
    suspense,
    refetch,
} = useApiAuctionDetail(id, {
    query: {
        queryKey: [AUCTION_DETAIL_QUERY_KEY_PREFIX, ...getApiAuctionDetailQueryKey(id)],
    },
});

// COMPUTED
const isMonoVersion = computed<boolean>(() => isAuctionMono(auction.value) || isAuctionBundleMono(auction.value));
const mainAsset = computed<AuctionAssetBusEntity | undefined>(() => auction.value?.mainAsset);
const assetType = computed<AssetType>(() => mainAsset.value?.assetType || AssetType.Bus);
const hasToBeApproved = computed<boolean>(
    () =>
        auction.value?.state === AuctionState.Draft &&
        !auction.value.acceptedAt &&
        (!isLoggedIn.value || auction.value.sellerCompanyId === session.value?.companyId)
);

// WATCHERS
watch(isLoggedIn, (value, oldValue) => {
    value !== oldValue && refetch();
});

// Perform await only if the id is correct resolved to the path
!!id.value && (await suspense());

if (slug.value === auction.value?.id) {
    // Redirect to correct slug
    navigateTo(localePath({ name: 'auction-slug', params: { slug: auction.value.slug } }));
} else if (
    !id.value ||
    error.value?.data?.error.code === BackendErrorCode.EntityNotFound || // backend 404
    error.value?.data?.error.code === BackendErrorCode.InvalidUUID || // backend 400
    slug.value !== auction.value?.slug // Slug is not correct
) {
    showError({ statusCode: 404 });
} else if (isError.value) {
    // Backend 500
    showError({ statusCode: 500 });
}

// SEO
useSeoMeta({
    title: () =>
        tt('seo.auction.title', {
            asset: tt(`asset.type.${assetType.value}`, 1), // Single form in ADP
            brand: mainAsset.value?.brand.brandValue,
            model: mainAsset.value?.model.modelValue,
            version: mainAsset.value?.version.versionValue,
            vin: mainAsset.value?.vin,
        }),
    ogTitle: tt('seo.auction.ogTitle', {
        asset: tt(`asset.type.${assetType.value}`, 1),
        brand: mainAsset.value?.brand.brandValue,
        model: mainAsset.value?.model.modelValue,
        version: mainAsset.value?.version.versionValue,
    }),
    description: tt('seo.auction.description', {
        asset: tt(`asset.type.${assetType.value}`, 1),
        brand: mainAsset.value?.brand.brandValue,
        model: mainAsset.value?.model.modelValue,
        version: mainAsset.value?.version.versionValue,
        vin: mainAsset.value?.vin,
    }),
    ogDescription: tt('seo.auction.ogDescription', {
        asset: tt(`asset.type.${assetType.value}`, 1),
        brand: mainAsset.value?.brand.brandValue,
        model: mainAsset.value?.model.modelValue,
        version: mainAsset.value?.version.versionValue,
        year: td(mainAsset.value?.firstRegistration!, 'mmyyyy'),
        euroNorm: tt(`asset.euroNorm.${mainAsset.value?.euroNorm!}`),
        kilometers: formatUnits.km(mainAsset.value?.km!),
    }),
    ...(mainAsset.value?.images.base[0]
        ? {
              ogImage: {
                  url: image(mainAsset.value?.images.base[0], { width: 1280, height: 960 }),
                  width: '1280px',
                  height: '960px',
                  alt: `Fleequid ${mainAsset.value?.brand.brandValue} ${mainAsset.value?.model.modelValue} ${mainAsset.value?.version.versionValue} ${mainAsset.value?.vin}`,
              },
          }
        : {}),
});
</script>
<template>
    <article v-if="auction && mainAsset">
        <AdpToBeApprovedToolbarAlert v-if="hasToBeApproved" />

        <BaseWidthContainer class="relative gap-7 py-6 lg:grid lg:grid-cols-3">
            <div class="flex flex-col gap-y-12 lg:col-span-2">
                <AdpBreadcrumbs v-if="isMonoVersion" :asset="mainAsset" />
                <section class="flex flex-col gap-y-3">
                    <AdpTitleSection :auction="auction" />
                    <AdpKeyFeaturesBadges :auction="auction" />
                </section>

                <template v-if="isMonoVersion">
                    <AdpGallery :asset="mainAsset" />
                    <AdpSpecs :article="auction" />
                    <AdpDetails :article="auction" />
                </template>
                <AdpMultiGrid v-else :auction="auction" />
            </div>
            <div class="sticky bottom-2 z-10 mt-6 lg:static lg:col-span-1 lg:mt-0">
                <div class="lg:sticky lg:top-[92px]">
                    <AdpAuctionPanel :auction="auction" />
                    <AdpAuctionPanelContactUs class="mt-6 hidden lg:block" />
                </div>
            </div>
        </BaseWidthContainer>
        <MktgWhyFleequid class="border-b border-white/15" />
    </article>
</template>
