<script setup lang="ts">
import { joinURL } from 'ufo';
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity } from '~/apiClient';

// CONSTANTS
const documents = ['cocDocument', 'registrationDocument', 'maintenanceCard'] as const;

// PROPS
defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const baseURL = useRuntimeConfig().public.cdnMediaBaseUrl;
</script>

<template>
    <div class="flex flex-col gap-6">
        <h3 class="text-2xl font-semibold">{{ tt('adp.papers') }}</h3>
        <div class="flex flex-wrap gap-3">
            <UPopover v-if="!asset.cocDocument" mode="hover" :popper="{ placement: 'top' }">
                <UButton
                    color="gray"
                    variant="solid"
                    size="lg"
                    :label="tt(`adp.cocDocument`)"
                    leading-icon="i-mdi-file-document-remove-outline"
                    disabled
                />
                <template #panel>
                    <div class="p-4">
                        {{ tt('adp.cocDocumentAbsent') }}
                    </div>
                </template>
            </UPopover>
            <template v-for="doc in documents" :key="doc">
                <UButton
                    v-if="asset[doc]"
                    variant="soft"
                    size="lg"
                    :label="tt(`adp.${doc}`)"
                    leading-icon="i-mdi-tray-download"
                    :to="joinURL(baseURL, asset[doc]!)"
                    target="_blank"
                />
            </template>
        </div>
    </div>
</template>
