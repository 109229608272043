<script setup lang="ts">
import type { Button } from '#ui/types';
import type { PropType } from 'vue';

export interface ToolbarAlertAction extends Button {
    click?: (...args: any[]) => void;
    class?: String;
}

defineProps({
    icon: { type: String, default: undefined },
    title: { type: String, required: true },
    description: { type: String, default: null },
    actions: { type: Array as PropType<ToolbarAlertAction[]>, default: undefined },
});

// HANDLERS
const onAction = (action: ToolbarAlertAction): void => {
    if (action.click) {
        action.click();
    }
};
</script>

<template>
    <div class="sticky inset-x-0 top-0 z-50 w-full items-center justify-center text-white ring-1 ring-gray-300">
        <BaseWidthContainer
            class="relative flex min-h-[--header-height] flex-col flex-nowrap justify-between gap-4 py-4 md:flex-row md:items-center md:py-3"
        >
            <div class="flex grow flex-row items-start gap-3 lg:items-center">
                <UIcon v-if="icon" class="h-6 w-6" :name="icon" />
                <div class="flex grow flex-col gap-1 lg:flex-row lg:gap-3">
                    <div class="text-sm font-bold leading-5 tracking-normal md:text-base md:leading-6">{{ title }}</div>
                    <div class="text-sm font-medium leading-5 tracking-normal md:text-base md:leading-6">{{ description }}</div>
                </div>
            </div>
            <div class="flex flex-col gap-1 md:flex-row">
                <UButton v-for="(action, index) in actions" :key="index" v-bind="action" @click.stop="onAction(action)" />
            </div>
        </BaseWidthContainer>
    </div>
</template>

<style scoped></style>
